import fetch from 'auth/FetchInterceptor'
import { ASSIGNED_GROUPS_IDS } from 'redux/constants/Auth'
import { SCHEDULE_EMAIL_REPORT } from 'utils/api-routes'
import { TableSorting } from 'utils/enum'
import { formatFiltersForRest } from 'utils/filter'

export const getAllLogs = (pageSize, pageNumber, filters, sorting, groupId = false) => {
  let formattedFilters = formatFiltersForRest(filters)
  let sortingStr = ''
  let groupFilter = groupId ? `&filter=groupId||eq||${groupId}` : ''
  if (sorting.sortField && sorting.sortOrder)
    sortingStr = `&sort=${sorting.sortField},${TableSorting[sorting.sortOrder]}&join=user`

  return fetch({
    url: `activity-logger?limit=${pageSize}&page=${pageNumber}${sortingStr}${formattedFilters}${groupFilter}`,
    method: 'get',
  })
    .then((data) => data)
    .catch((err) => console.log(err))
}

export const getAllClientLogs = (clientId) => {
  return fetch({
    url: `client-uploads?filter=clientId||eq||${clientId}&sort=updatedAt,DESC`,
    method: 'get',
  })
    .then((data) => data.data || [])
    .catch((err) => console.log(err))
}
export const downloadClientLogs = ({ clientId, login }) => {
  return fetch({
    url: `client-uploads/download/debugLogs/${clientId}`,
    method: 'get',
  })
    .then((data) => ({
      login,
      data,
    }))
    .catch((err) => console.log(err))
}

export const getAIToolUsage = (values) => {
  return fetch({
    url: `ai-tool-usage/groups/${
      values?.groupId
    }?startDate=${values?.dates[0]?.toISOString()}&endDate=${values?.dates[1]?.toISOString()}`,
    // url: `ai-tool-usage/groups/${groupId}`,
    method: 'get',
  })
    .then((data) => data || [])
    .catch((err) => console.log(err))
}

export const getAIToolUsagePerDomain = (values) => {
  return fetch({
    url: `ai-tool-usage/domains/${
      values?.clientId
    }?startDate=${values?.dates[0]?.toISOString()}&endDate=${values?.dates[1]?.toISOString()}`,
    // url: `ai-tool-usage/groups/${groupId}`,
    method: 'get',
  })
    .then((data) => data || [])
    .catch((err) => console.log(err))
}

export const getAIToolUsageDetails = (values) => {
  return fetch({
    url: `ai-tool-usage?filter=domain||eq||${values?.domain}&filter=client.id||eq||${values.clientId}`,
    method: 'get',
  })
    .then((data) => data || [])
    .catch((err) => console.log(err))
}

export const getAllAIToolUsageDetails = (pageSize, pageNumber, filters, sorting) => {
  const assignedGroupIds = localStorage.getItem(ASSIGNED_GROUPS_IDS) || ''
  let localFilter = { ...filters }

  let localFilters = { ...filters }
  if (assignedGroupIds) {
    if (!localFilters['client.groupId']) localFilters['client.groupId'] = {}
    if (!localFilters['client.groupId'].in) localFilters['client.groupId'].in = assignedGroupIds
  }
  let formattedFilters = formatFiltersForRest(localFilter)
  console.log({ formattedFilters, localFilter })

  let sortingStr = ''
  if (sorting.sortField && sorting.sortOrder)
    sortingStr = `&sort=${sorting.sortField},${TableSorting[sorting.sortOrder]}`

  return fetch({
    url: `ai-tool-usage?limit=${pageSize}&page=${pageNumber}&join=clients${sortingStr}${formattedFilters}${
      assignedGroupIds && `&filter=client.groupId||in||${assignedGroupIds}`
    }`,
    method: 'get',
  })
    .then((data) => data)
    .catch((err) => console.log(err))
}

export const downloadAiUsageAsCsv = () => {
  return fetch({
    url: 'ai-tool-usage/export-csv',
    method: 'get',
  })
    .then((data) => data)
    .catch((err) => console.log(err))
}

export const sendManualEmail = () => {
  return fetch({
    url: `${SCHEDULE_EMAIL_REPORT}/manual-email`,
    method: 'get',
  })
    .then((data) => data)
    .catch((err) => console.log(err))
}
